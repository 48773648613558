export const playPageTabs = ["Leaderboard", "chat"];

export const leaderboardCards = [
  { nickName: "0xD31...9c2b", score: 12345 },
  { address: "0xD31...9c2b", score: 12345 },
  { address: "0xD31...9c2b", score: 12345 },
  { address: "0xD31...9c2b", score: 12345 },
  { address: "0xD31...9c2b", score: 12345 },
  { address: "0xD31...9c2b", score: 12345 },
  { address: "0xD31...9c2b", score: 12345 },
  { address: "0xD31...9c2b", score: 12345 },
  { address: "0xD31...9c2b", score: 12345 },
  { address: "0xD31...9c2b", score: 12345 },
  { address: "0xD31...9c2b", score: 12345 },
  { address: "0xD31...9c2b", score: 12345 },
  { address: "0xD31...9c2b", score: 12345 },
  { address: "0xD31...9c2b", score: 12345 },
  { address: "0xD31...9c2b", score: 12345 },
  { address: "0xD31...9c2b", score: 12345 },
  { address: "0xD31...9c2b", score: 12345 },
  { address: "0xD31...9c2b", score: 12345 },
  { address: "0xD31...9c2b", score: 12345 },
  { address: "0xD31...9c2b", score: 12345 },
];

export const chatItemsDefault = [
  {
    nickname: "nickname2022",
    message:
      "a designer, a layout designer, a webmaster to generate several designer, a webmasterc, a webmaster text fish. ",
  },
  {
    nickname: "nickname2022",
    message:
      "a designer, a layout designer, a webmaster to generate several designer, a webmasterc, a webmaster text fish. ",
  },
];
