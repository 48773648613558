import img0 from "../../assets/gif/marketplace/0.gif";

export const cards = [
    {
        img: img0,
        name: "Zooverse",
        price: 50,
        left: 3
    },
    {
        img: img0,
        name: "Zooverse",
        price: 50,
        left: 3
    },
    {
        img: img0,
        name: "Zooverse",
        price: 50,
        left: 3
    },
    {
        img: img0,
        name: "Zooverse",
        price: 50,
        left: 3
    },
    {
        img: img0,
        name: "Zooverse",
        price: 50,
        left: 3
    },
    {
        img: img0,
        name: "Zooverse",
        price: 50,
        left: 3
    },
]